<template>
  <div class="article ql-container ql-snow">
    <div v-if="!this.$route.query.id" class="list">
      <router-link v-for="(item,index) in announcementList" :key="index" class="listItem"
          :to="{name:'Article',query:{cat_id:cat_id,id:item.id}}">
        <div id="itemTitle">{{ item.title }}</div>
        <div id="itemDate">{{ item.add_time|moment }}</div>
        <div id="itemBtn">查看</div>
      </router-link>
      <div class="pages">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="PageSize"
            layout="prev, pager, next, jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <div v-else class="content">
      <router-link :to="{name:'Article',query:{cat_id:cat_id}}" class="contentBack">返回公告列表</router-link>
      <div id="flex">
        <div class="contentTitle">{{ announcementList[0].title }}</div>
        <div class="contentInfo">
          <div class="contentDate">发布时间：{{ announcementList[0].add_time|moment }}</div>
          <div class="contentClick">点击数：{{ announcementList[0].click }}</div>
        </div>
        <div id="content" v-html="announcementList[0].content"></div>
      </div>

    </div>
  </div>
</template>
<script>
import {apiArticleList} from "@/request/API";

export default {
  name: '',
  props: {
    // id:this.$route.query.id,
    cat_id: String,
    id: Number
  },
  components: {},
  data() {
    return {
      announcementList: [
        {title: ''}
      ],
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 6,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val
      this.getArticleList(this.$route.query.id)
    },
    getArticleList(id) {
      apiArticleList({
        id: id,
        page: this.currentPage,
        PageSize: this.PageSize,
        cat_id: this.cat_id
      }).then(res => {
        this.announcementList = res.data.list
        this.totalCount = res.data.total
      })
    }
  },
  mounted() {
    this.getArticleList(this.$route.query.id)
  },
  watch: {
    cat_id(val, oldVal) {//普通的watch监听
      this.cat_id = val
      this.currentPage = 1
      this.getArticleList(this.$route.query.id)
    },
    id(val, oldVal) {//普通的watch监听]
      this.getArticleList(this.$route.query.id)
    },
  },
  computed: {},
}
</script>

<style scoped>
#content {
  /*padding: 50px 40px;*/
  /*width: 871px;*/
  overflow: auto;
  margin: 0;
}

#content >>> img {
  width: 100% !important;
  /*height: 100% !important;*/
  object-fit: contain !important;
}

</style>

<style lang='less' scoped>
.article {
  border: none !important;
  //width: 951px;
  .list {
    //width: 1200px;
    padding: 40px 20px 50px;
    //height: 810px;
    background: #FFFFFF;

    .listItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      border-bottom: 1px solid #E2E2E2;
      padding: 0 30px;

      #itemTitle {
        width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #5B5B5B;
      }

      #itemDate {
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
      }

      #itemBtn {
        width: 100px;
        height: 30px;
        background: #434ED6;
        border-radius: 20px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #FFFFFF;
      }
    }

    .listItem:hover {
      background: #FFFAF0;
    }

    .pages {
      margin: 29px 0 0 0;
      display: flex;
      justify-content: center;
    }
  }

  .content {
    //width: 871px;
    //height: 810px;
    background: #FFFFFF;
    padding: 0 40px 50px;

    .contentBack {
      display: block;
      padding: 0 0 0 18px;
      font-size: 14px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      line-height: 20px;
      margin: 20px 0 28px;
      cursor: pointer;
      background: url("../../../assets/image/Home/articleBack.png") no-repeat;
      background-position: 0 5px;
    }

    .contentBack:hover {
      color: #434ED6;
      background: url("../../../assets/image/Home/articleBackHover.png") no-repeat;
      background-position: 0 5px;
    }

    #flex {
      display: flex;
      flex-direction: column;
      align-items: center;

      .contentTitle {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
        line-height: 22px;
        margin: 0 0 10px 0;
      }

      .contentInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
        line-height: 20px;
        margin: 0 0 10px 0;

        div {
          margin: 0 15px;
        }
      }

      #content {

      }

      #signature {

      }
    }

  }
}
</style>
